import { wrapCreateBrowserRouter } from '@sentry/react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import Layout from './components/Layout/Layout';
import PageLayout from './components/Layout/PageLayout';
import NotAccessible from './components/NotAccessible/NotAccessible';

import { Box, MantineProvider } from '@mantine/core';
import '@mantine/core/styles.css';

import AuthProvider from './providers/AuthProvider';
import { authenticatedRoutes, nonAuthenticatedRoutes } from './routes';
import * as Reporting from './services/reporting';
import { DEBUG_MODE_ENABLED, ENVIRONMENT } from './settings';
import { theme } from './styles/theme';

import './styles/tailwind.css';
import '@mantine/carousel/styles.css';

Reporting.init({
  environment: ENVIRONMENT,
  debugModeEnabled: DEBUG_MODE_ENABLED as boolean
});

const sentryCreateBrowserRouter = wrapCreateBrowserRouter(createBrowserRouter);

const router = sentryCreateBrowserRouter([
  {
    id: 'not-authenticated',
    errorElement: <NotAccessible variant="text404" />,
    element: <PageLayout />,
    children: nonAuthenticatedRoutes
  },
  {
    id: 'root',
    path: '/',
    element: <Layout />,
    errorElement: <NotAccessible variant="text404" />,
    children: authenticatedRoutes
  }
]);

function App() {
  return (
    <MantineProvider theme={theme}>
      <AuthProvider>
        <Box miw={360}>
          <RouterProvider router={router} />
        </Box>
      </AuthProvider>
    </MantineProvider>
  );
}

export default App;
