import React, { useContext, useEffect, useState } from 'react';

import AuthContext from '../../context/Auth';
import useOrganizationStore from '../../hooks/useOrganizationStore';
import { BACKEND_URL } from '../../settings';
import { Organization } from '../../types/Organization';
import { fetchWithAuth } from '../../utils/fetchWithAuth';
import { getInitials } from '../../utils/formatters';

interface OrganizationAvatarProps {
  organization: Organization | null;
  onAvatarChange?: (newAvatar: string) => void;
  onError?: (error: string) => void;
  size?: 'small' | 'large';
  editable?: boolean;
}

export const OrganizationAvatar = ({
  organization,
  onAvatarChange,
  onError,
  size = 'small',
  editable = false
}: OrganizationAvatarProps) => {
  const [isUploading, setIsUploading] = useState(false);
  const selectedOrganization = useOrganizationStore(
    (state) => state.selectedOrganization
  );
  const setSelectedOrganization = useOrganizationStore(
    (state) => state.setSelectedOrganization
  );
  const { refetch } = useContext(AuthContext);

  if (!organization) return null;

  if (organization.id === selectedOrganization?.id) {
    // this is to handle when the current organization is the selected one...
    // we should be storing all orgs in the store and update from there, but this is lighter
    organization = selectedOrganization;
  }

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (!file) return;

    const formData = new FormData();
    formData.append('avatar', file);
    setIsUploading(true);

    try {
      const response = await fetchWithAuth(
        `${BACKEND_URL}/api/organizations/${organization.id}/avatar/`,
        {
          method: 'POST',
          body: formData
        }
      );

      const data = await response.json();

      if (!response.ok) {
        const errorMessage =
          data.detail || 'Failed to update organization avatar';
        onError?.(errorMessage);
        throw new Error(errorMessage);
      }

      // Update the selected organization if this is the current organization
      if (selectedOrganization?.id === organization.id) {
        setSelectedOrganization({
          ...selectedOrganization,
          avatar: data.avatar
        });
      }

      // Call onAvatarChange to update local state
      onAvatarChange?.(data.avatar);

      // Refetch user data to update all organization data
      await refetch();
    } catch (error) {
      const errorMessage =
        error instanceof Error ? error.message : 'Failed to upload avatar';
      if (onError) {
        onError(errorMessage);
      }
    } finally {
      setIsUploading(false);
    }
  };

  const avatarContent = organization.avatar ? (
    <div>
      <img
        src={organization.avatar}
        alt={organization.name}
        className="size-full object-cover rounded-md aspect-square"
      />
    </div>
  ) : (
    <div className="size-full rounded-md bg-black flex items-center justify-center text-white font-500 aspect-square">
      {getInitials(organization.name)}
    </div>
  );

  if (!editable) {
    return avatarContent;
  }

  return (
    <div className="relative">
      {avatarContent}
      <input
        type="file"
        id={`${editable}-avatarInput-${organization.id}`}
        data-testid="avatar-input"
        accept="image/png, image/jpeg, image/jpg"
        onChange={handleFileChange}
        className="hidden"
        disabled={isUploading}
      />
      <label
        htmlFor={`${editable}-avatarInput-${organization.id}`}
        className={`absolute inset-0 flex items-center justify-center opacity-0 hover:opacity-100 transition-opacity cursor-pointer ${
          isUploading ? 'cursor-not-allowed' : ''
        }`}
      >
        <div className="flex items-center bg-black/50 px-2 py-1 rounded-lg">
          <img
            src="/icons/editWhite.svg"
            alt="edit"
            className={`${size === 'large' ? 'mr-2 w-3 h-3' : 'w-2.5 h-2.5'}`}
          />
          {size === 'large' && (
            <span className="text-white font-500 text-xs">
              {isUploading ? 'Uploading...' : 'Edit'}
            </span>
          )}
        </div>
      </label>
    </div>
  );
};

export default OrganizationAvatar;
