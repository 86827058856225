import { SidebarCollapse, SidebarExpand } from 'iconoir-react';
import { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';

import { Button, Navigation } from '@cavela/ui';
import { Add, Menu, PersonRounded, ShoppingBag } from '@mui/icons-material';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import ShoppingBagRoundedIcon from '@mui/icons-material/ShoppingBagRounded';

import { AnimatePresence, motion } from 'framer-motion';
import useFeatureFlag from 'src/hooks/useFeatureFlag';

import AuthContext from '../../context/Auth';
import useOrganizationStore from '../../hooks/useOrganizationStore';
import OrganizationSelector from './OrganizationSelector';
import UserAvatar from './UserAvatar';
import useSidebarStore from './useSidebarStore';

const SidebarNav = () => {
  const { pathname } = window.location;
  const { userInfo } = useContext(AuthContext);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { isCollapsed, toggleIsCollapsed, setScreenResizing } =
    useSidebarStore();
  const { selectedOrganization, setSelectedOrganization } =
    useOrganizationStore();

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const routes = [
    {
      label: 'Products',
      route: '/',
      icon: ShoppingBagRoundedIcon,
      selected: pathname === '/' || pathname.match(/products/)
    },
    {
      label: 'Profile',
      route: '/profile',
      icon: PersonRoundedIcon,
      selected: pathname.match(/profile/)
    }
  ];

  return (
    <>
      <div className="lg:hidden absolute ml-10 mt-4">
        {isMenuOpen ? (
          <button
            onClick={toggleMenu}
            className="fixed bottom-4 left-4 bg-[#030FEF] rounded-lg p-2 z-50"
          >
            <Menu htmlColor="white" />
          </button>
        ) : (
          <button
            onClick={toggleMenu}
            className="fixed bottom-4 left-4 bg-[#030FEF] rounded-lg p-2 z-10"
          >
            <Menu htmlColor="white" />
          </button>
        )}
      </div>

      <motion.div
        initial={{ width: isCollapsed ? 52 : 300 }}
        animate={{ width: isCollapsed ? 52 : 300 }}
        transition={{ type: 'spring', stiffness: 300, damping: 30 }}
        onAnimationStart={() => setScreenResizing(true)}
        onAnimationComplete={() => setScreenResizing(false)}
        className="h-full pl-4 py-4 hidden lg:flex gap-3 flex-col justify-between text-base select-none cursor-pointer"
        onClick={() => toggleIsCollapsed()}
      >
        <div
          className="flex flex-col gap-3 overflow-visible"
          data-testid="sidebar-menu"
        >
          <OrganizationSelector isCollapsed={isCollapsed} />

          {routes.map((route) => (
            <Link
              key={route.label}
              to={route.route}
              className={`h-9 w-full flex items-center hover:bg-gray-4 rounded-lg overflow-hidden cursor-pointer ${route.selected ? 'bg-gray-4' : ''}`}
              data-tooltip-id={route.label}
              data-tooltip-content={route.label}
              onClick={(e) => e.stopPropagation()}
            >
              <div className="size-9 flex items-center justify-center shrink-0">
                <route.icon
                  className="text-black"
                  style={{
                    fontSize: 20
                  }}
                />
              </div>

              <span className="text-black font-500">{route.label}</span>
            </Link>
          ))}

          <Link
            to="/new-rfq"
            className="h-9 w-full flex items-center justify-center bg-p-cavela-blue rounded-full overflow-hidden cursor-pointer"
            data-tooltip-id="New Product"
            data-tooltip-content="New Product"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="size-9 flex items-center justify-center shrink-0">
              <AddRoundedIcon
                className="text-white"
                style={{
                  fontSize: 20
                }}
              />
            </div>

            <motion.span
              className="whitespace-nowrap text-white font-c font-700"
              initial={{
                width: isCollapsed ? 0 : 'auto',
                marginRight: isCollapsed ? 0 : 14
              }}
              animate={{
                width: isCollapsed ? 0 : 'auto',
                marginRight: isCollapsed ? 0 : 14
              }}
            >
              New Product
            </motion.span>
          </Link>
        </div>

        <div className="flex flex-col gap-3 sticky bottom-0">
          <div
            className="w-full h-10 flex gap-1 items-center cursor-auto"
            onClick={(e) => e.stopPropagation()}
            data-tooltip-id="user"
            data-tooltip-content={userInfo?.username}
          >
            <div className="size-9 shrink-0 rounded-md overflow-hidden">
              <UserAvatar
                displayName={userInfo?.firstName || userInfo?.username || ''}
                avatar={userInfo?.avatar}
              />
            </div>
            <div className="w-full flex flex-col truncate select-text">
              <span className="truncate text-black font-500">
                {userInfo?.firstName || userInfo?.username}
              </span>
              <span className="truncate text-light-gray font-400">
                {userInfo?.email}
              </span>
            </div>
          </div>

          <div
            className="size-9 cursor-pointer flex items-center justify-center justify-self-end"
            onClick={(e) => {
              toggleIsCollapsed();
              e.stopPropagation();
            }}
          >
            <AnimatePresence>
              {isCollapsed ? (
                <motion.div
                  key="expanded"
                  className="absolute"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                >
                  <SidebarExpand color="black" />
                </motion.div>
              ) : (
                <motion.div
                  key="collapsed"
                  className="absolute"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                >
                  <SidebarCollapse color="black" />
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        </div>
      </motion.div>

      <AnimatePresence>
        {isMenuOpen ? (
          <motion.div
            initial={{ x: -252 }}
            animate={{ x: 0 }}
            exit={{ x: -252 }}
            transition={{ type: 'spring', stiffness: 300, damping: 30 }}
            className="fixed top-0 left-0 w-[252px] h-full z-40 p-4 pb-16 flex lg:hidden gap-3 flex-col bg-white"
          >
            <div className="flex flex-col gap-3 overflow-visible">
              <OrganizationSelector isCollapsed={false} />
            </div>

            <div className="flex-1 flex flex-col gap-3">
              {routes.map((route) => (
                <Link
                  key={route.label}
                  to={route.route}
                  className={`h-9 w-full flex items-center hover:bg-gray-4 rounded-lg overflow-hidden cursor-pointer ${route.selected ? 'bg-gray-4' : ''}`}
                >
                  <div className="size-9 flex items-center justify-center shrink-0">
                    <route.icon
                      className="text-black"
                      style={{
                        fontSize: 20
                      }}
                    />
                  </div>

                  <span className="text-black font-500">{route.label}</span>
                </Link>
              ))}

              <Link
                to="/new-rfq"
                className="h-9 w-full flex items-center justify-center bg-p-cavela-blue rounded-full overflow-hidden cursor-pointer"
              >
                <div className="size-9 flex items-center justify-center shrink-0">
                  <AddRoundedIcon
                    className="text-white"
                    style={{
                      fontSize: 20
                    }}
                  />
                </div>

                <span className="whitespace-nowrap text-white font-c font-700 w-auto mr-[14px]">
                  New Product
                </span>
              </Link>
            </div>

            <div className="w-full h-10 flex gap-1 items-center">
              <div className="size-9 shrink-0 rounded-md overflow-hidden">
                <UserAvatar
                  displayName={userInfo?.firstName || userInfo?.username || ''}
                  avatar={userInfo?.avatar}
                />
              </div>
              <div className="w-full flex flex-col truncate">
                <span className="truncate text-black font-500">
                  {userInfo?.firstName || userInfo?.username}
                </span>
                <span className="truncate text-light-gray font-400">
                  {userInfo?.email}
                </span>
              </div>
            </div>
          </motion.div>
        ) : null}
      </AnimatePresence>

      <div className="z-50">
        {isCollapsed && routes.map((route) => <Tooltip id={route.label} />)}
        {isCollapsed && <Tooltip id="New Product" />}
        {isCollapsed && <Tooltip id="user" />}
      </div>
    </>
  );
};

export default SidebarNav;
