import useOrganizationStore from '../hooks/useOrganizationStore';
import authService from '../services/authService';
import { failure } from '../services/logger';
import { CustomError } from '../types/errorTypes';

const fetchWithAuth = async (url, options = {}) => {
  const storedToken = authService.getAuthToken();
  const urlToken = authService.getUrlAuthToken(window.location.href);
  if (!storedToken && !urlToken) {
    throw new CustomError({
      message: `noAuthAvailable`
    });
  }
  var res;
  authService.saveImpersonationFromUrl(window.location.href);
  res = await fetchWithStoredAuthToken(url, options);

  if (!res.ok) {
    failure('Fetch with auth failed:', res.statusText);
    // if there's a url token, then we should try that one before logging out
    const tokenUrl = window.location.href;
    const nextTryAuthToken = authService.getUrlAuthToken(tokenUrl);
    if (nextTryAuthToken) {
      authService.saveAuthToken(nextTryAuthToken);
      authService.saveAuthFromUrl(tokenUrl);
      res = await fetchWithStoredAuthToken(url, options);
      if (res.ok) {
        return res;
      }
    }
    // Only logout for authentication errors (401)
    if (res.status === 401 || res.status === 403) {
      authService.logout();
    }
  }
  return res;
};

const fetchWithStoredAuthToken = async (url, options = {}) => {
  const authToken = authService.getAuthToken();
  const { selectedOrganization } = useOrganizationStore.getState();

  const headers = {
    ...options.headers,
    Authorization: `Token ${authToken}`,
    ...(selectedOrganization?.id && {
      'X-Organization-Id': selectedOrganization.id.toString()
    })
  };

  const impersonation = authService.getImpersonation();
  if (impersonation && impersonation !== '0') {
    headers['Impersonation'] = impersonation;
  }
  return fetch(url.toString(), { ...options, headers });
};

const fetchWithJWTAuth = async (url, options = {}) => {
  // Retrieve the user object from local storage
  const userJson = localStorage.getItem('user');
  if (!userJson) {
    throw new Error('Not authenticated');
  }
  const user = userJson ? JSON.parse(userJson) : null;
  const token = user ? user.access : null;
  const { selectedOrganization } = useOrganizationStore.getState();

  // Include the Authorization header with the JWT token if available
  const headers = {
    ...options.headers,
    Authorization: token ? `Bearer ${token}` : '',
    ...(selectedOrganization?.id && {
      'X-Organization-Id': selectedOrganization.id.toString()
    })
  };

  let response = await fetch(url, { ...options, headers });

  // If the token is expired or invalid, try to refresh it
  if (response.status === 401) {
    const newToken = await authService.refreshAccessToken();
    headers['Authorization'] = `Bearer ${newToken}`;
    response = await fetch(url, { ...options, headers }); // Retry the request with the new token
  }
  return response;
};

export { fetchWithAuth };
