import * as Sentry from '@sentry/react';
import React from 'react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType
} from 'react-router-dom';

import { ImpersonatingInfo } from '../hooks/useUserInfo';

export function init({
  environment,
  debugModeEnabled = false
}: {
  environment: string;
  debugModeEnabled: boolean;
}) {
  const enabled = !environment.startsWith('local');
  Sentry.init({
    dsn: 'https://9643191e8aec0f45e15a6e8bf9f652b5@o4506826568695808.ingest.us.sentry.io/4506829241057280',
    debug: debugModeEnabled,
    enabled,
    environment,
    tracesSampleRate: debugModeEnabled ? 1.0 : 0.01,
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      })
    ]
  });
  //eslint-disable-next-line no-console
  console.info(
    `[Reporting] Environment: ${environment}. Debug mode: ${debugModeEnabled}. Sentry enabled: ${enabled}`
  );
}

export function captureException({
  error,
  extra
}: {
  error: Error;
  extra?: any;
}) {
  Sentry.withScope((scope) => {
    scope.clear();
    Sentry.captureException(error, { extra });
  });
}

export function captureMessage({
  message,
  extra
}: {
  message: string;
  extra?: any;
}) {
  Sentry.withScope((scope) => {
    scope.clear();
    Sentry.captureMessage(message, { extra });
  });
}

export function setUser({
  id,
  email,
  username,
  impersonating
}: {
  id: string;
  email: string;
  username: string;
  impersonating: ImpersonatingInfo;
}) {
  const user = { id, email, username };
  if (impersonating) {
    const user_impersonation = {
      impersonatingId: impersonating.id,
      impersonatingUsername: impersonating.username
    };
    Sentry.setUser({ ...user, ...user_impersonation });
    Sentry.setTag('impersonating', true);
  } else {
    Sentry.setUser(user);
    Sentry.setTag('impersonating', false);
  }

  Sentry.setTag('cavela', email.includes('@cavela.com'));
}
